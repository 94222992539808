export const useSearchUrl = () => {
    const { locale } = useI18n();

    return function searchUrl(query: string, manufacturerPath?: string) {
        // Ignore non-word characters for min length comparison to make sure we really have something to work with.
        if (query.replace(/[\W_]/g, "").trim().length < MIN_SEARCH_LENGTH) {
            return;
        }

        const fixedQuery = encodeURIComponent(
            query
                .replace(/[><]/g, "") // Remove angle brackets since they are considered unsafe for our URLs.
                .replace(/\t+/g, " ") // Replace tabs with spaces since they break the server
                .trim()
        );

        const urlParts = [locale.value, "search", manufacturerPath, fixedQuery].filter((v) => !!v);

        return "/" + urlParts.join("/");
    };
};
